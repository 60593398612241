import {Injectable} from '@angular/core';
import {APIService, MedicalResultTypeSerializer} from './api.service';
import {filter, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {combineLatest, Observable, Subject} from 'rxjs';
import {UserAuthService} from './user-auth.service';
import {User} from '../models/user';
import {LocalStorageService} from 'ngx-webstorage';
import {JWTService} from './jwt.service';
import {MedicalResultType} from '../models/medical-result-type';
import {NoteView} from '../portal/@slide-panel-preview/ccm-note/note-view-selector/note-view-selector.component';
import {assignOrCreate} from '../models/model-base';
import {PatientsFilterModel} from '../portal/patients/patients.component';
import {PhysicianDashboardFilterModel} from '../portal/dashboard/physician-dashboard/physician-dashboard.component';
import {VisitPlanerFilterModel} from '../portal/visit/visit-planner/visit-planner.component';
import {CalendarEventType} from '../models/calendar-event';

export interface FilterOption {
    value: any;
    name: string;
    permission?: string;
}

export interface PatientFilter {
    followed: boolean;
    assigned_patients: boolean;
    assigned_facilities: boolean;
    ccm_consent: boolean;
    on_call: boolean;
    aco: boolean;
    member: boolean;
}

export interface PatientListFilter extends PatientFilter {
    payer_category: string;
}

export interface PatientFilterOption extends FilterOption {
    value: PatientFilter;
}

export interface DateRange {
    start_date: Date;
    end_date: Date;
    timezone?: string;
}

export type PatientsPhysicianDashboardFilterModel = PatientsFilterModel & PhysicianDashboardFilterModel;

export type VisitPlannerViewFilterModel = Partial<{showVisit: boolean; showHolidays: boolean; showEventTypes: Partial<{[key in CalendarEventType]: boolean}>}>;

@Injectable({
    providedIn: 'root',
})
export class FilterService {
    constructor(private userAuth: UserAuthService,
                private api: APIService,
                private jwt: JWTService,
                private storage: LocalStorageService) {
        this.daysFilter = this.storage.retrieve('daysFilter') || this.daysFilterOptions[1];
        this.fullPageListPageSize = this.storage.retrieve('fullPageListPageSize') || this.fullPageListPageSizeOptions[1];
        this.detailViewPageSize = this.storage.retrieve('detailViewPageSize') || this.detailViewPageSizeOptions[2];
        this.floorPlanView = this.storage.retrieve('floorPlanView') || 'single';
        this.noteView = this.storage.retrieve('noteView') || 'FULL_NOTE';
        userAuth.user.subscribe(user => {
            if (!user) return;
            this.patientsPhysicianDashboardFilterStorageKey = `patientsPhysicianDashboardFilter-${user?.id}`;
            this.patientsPhysicianDashboardFilter = this.storage.retrieve(this.patientsPhysicianDashboardFilterStorageKey) || {};
            this.visitPlannerFilterStorageKey = `visitPlannerFilter-${user.id}`;
            this.visitPlannerFilter = this.storage.retrieve(this.visitPlannerFilterStorageKey) || {};
            this.visitPlannerViewStorageKey = `visitViewFilter-${user.id}`;
            this.visitPlannerView = this.storage.retrieve(this.visitPlannerViewStorageKey) || {};
        });
        this.medicalResultTypes$.subscribe(types => this.medicalResultTypes = types.map(type => ({id: type.id, name: type.name})));
        this.criticalVitalTypes$.subscribe(types => this.criticalVitalTypes = types);
    }

    static activeFilter$ = new Subject<any>();
    medicalResultTypes: {id: number; name: string}[];
    criticalVitalTypes: MedicalResultTypeSerializer[];

    private _patientFilter: PatientFilterOption;
    get patientFilter(): PatientFilterOption {
        return this._patientFilter;
    }

    set patientFilter(value: PatientFilterOption) {
        this.storage.store('patientFilter', value);
        this._patientFilter = value;
    }

    private _daysFilter: FilterOption;
    get daysFilter(): FilterOption {
        return this._daysFilter;
    }

    set daysFilter(value: FilterOption) {
        this.storage.store('daysFilter', value);
        this._daysFilter = value;
    }

    private _fullPageListPageSize: FilterOption;
    get fullPageListPageSize(): FilterOption {
        return this._fullPageListPageSize;
    }

    set fullPageListPageSize(value: FilterOption) {
        this.storage.store('fullPageListPageSize', value);
        this._fullPageListPageSize = value;
    }

    private _detailViewPageSize: FilterOption;
    get detailViewPageSize(): FilterOption {
        return this._detailViewPageSize;
    }

    set detailViewPageSize(value: FilterOption) {
        this.storage.store('detailViewPageSize', value);
        this._detailViewPageSize = value;
    }

    private _floorPlanView: string;
    get floorPlanView(): string {
        return this._floorPlanView;
    }

    set floorPlanView(value: string) {
        this.storage.store('floorPlanView', value);
        this._floorPlanView = value;
    }

    private _noteView: NoteView;
    get noteView(): NoteView {
        return this._noteView;
    }

    set noteView(value: NoteView) {
        this.storage.store('noteView', value);
        this._noteView = value;
    }

    // Per-User filters

    private _patientsPhysicianDashboardFilter: Partial<PatientsPhysicianDashboardFilterModel>;
    patientsPhysicianDashboardFilterStorageKey: string;
    get patientsPhysicianDashboardFilter() {
        return this._patientsPhysicianDashboardFilter;
    }

    set patientsPhysicianDashboardFilter(value: Partial<PatientsPhysicianDashboardFilterModel>) {
        this.storage.store(this.patientsPhysicianDashboardFilterStorageKey, value);
        this._patientsPhysicianDashboardFilter = value;
    }

    private _visitPlannerFilter: Partial<VisitPlanerFilterModel>;
    private visitPlannerFilterStorageKey: string;
    get visitPlannerFilter() {
        return this._visitPlannerFilter;
    }

    set visitPlannerFilter(value) {
        this.storage.store(this.visitPlannerFilterStorageKey, value);
        this._visitPlannerFilter = value;
    }

    private _visitPlannerView: VisitPlannerViewFilterModel;
    private visitPlannerViewStorageKey: string;
    get visitPlannerView() {
        return this._visitPlannerView;
    }

    set visitPlannerView(value) {
        this.storage.store(this.visitPlannerViewStorageKey, value);
        this._visitPlannerView = value;
    }

    fullPageListPageSizeOptions: FilterOption[] = [20, 50, 100, 200].map(size => ({value: size, name: `${size} per page`}));
    detailViewPageSizeOptions: FilterOption[] = [50, 100, 200, 300, 400].map(size => ({value: size, name: `${size} per page`}));
    daysFilterOptions: FilterOption[] = [1, 3, 7, 30].map(d => ({value: d, name: `Last ${d} day${d > 1 ? 's' : ''}`}));

    private _user$: Observable<User> = this.userAuth.user;

    patientFilterOptions$$: Observable<PatientFilterOption[]> = this._user$.pipe(
        filter(u => !!u),
        switchMap(() => combineLatest([this._user$, this.userAuth.physicianTeamMemberships$])),
        map(([u, memberships]) => {
            const args: PatientFilter = {
                assigned_patients: null,
                assigned_facilities: null,
                followed: null,
                ccm_consent: null,
                on_call: null,
                aco: null,
                member: null,
            };
            const patientFilterOptions: PatientFilterOption[] = [
                {
                    value: {...args, assigned_patients: true},
                    name: 'All Assigned Patients',
                    permission: 'PERMISSION_READ_PATIENTS_ASSIGNED',
                },
                {
                    value: {...args, assigned_patients: true, ccm_consent: true},
                    name: 'CCM Assigned Patients',
                    permission: 'PERMISSION_READ_PATIENTS_ASSIGNED',
                },
                {
                    value: args,
                    name: 'All Patients',
                },
                {
                    value: {...args, followed: true},
                    name: 'Followed Patients',
                    permission: 'PERMISSION_FOLLOW_PATIENTS',
                },
                {
                    value: {...args, aco: true},
                    name: 'Members',
                },
            ];

            const processedPatientFilterOptions: PatientFilterOption[] = this._processOptionPermissions(patientFilterOptions, u);

            if (memberships.some(membership => membership.care_team_lead)) {
                processedPatientFilterOptions.unshift({
                    value: {...args, assigned_facilities: true},
                    name: 'Team Lead Facilities',
                });
            }

            if (memberships.some(membership => membership.on_call)) {
                processedPatientFilterOptions.push({
                    value: {...args, on_call: true},
                    name: 'On-Call Facilities',
                });
            }

            const storedPatientFilter = processedPatientFilterOptions.find(filter => this.storage.retrieve('patientFilter') && filter.name === this.storage.retrieve('patientFilter').name);
            if (storedPatientFilter && storedPatientFilter.permission) {
                this.patientFilter = u.permissions.includes(storedPatientFilter.permission) ? storedPatientFilter : null;
            } else if (storedPatientFilter) {
                this.patientFilter = storedPatientFilter;
            } else {
                this.patientFilter = processedPatientFilterOptions.find(filter => filter.name === 'All Patients');
            }

            return processedPatientFilterOptions;
        }),
        shareReplay(1)
    );

    get patientFilterOptions$(): Observable<PatientFilterOption[]> {
        return this.patientFilterOptions$$.pipe(take(1));
    }

    medicalResultTypes$: Observable<MedicalResultTypeSerializer[]> = this.api.MedicalResultTypeViewSet.list()
        .pipe(
            map(x => x.results.map(type => assignOrCreate(MedicalResultType, type))),
            this.jwt.cacheOnce()
        );

    criticalVitalTypes$: Observable<MedicalResultTypeSerializer[]> = this.medicalResultTypes$.pipe(map(x => x.filter(y => !!y.critical)), shareReplay(1));

    private _processOptionPermissions(opts: PatientFilterOption[], u: User): PatientFilterOption[] {
        return opts.filter(x => !x.permission || u.permissions.includes(x.permission));
    }

    dateRange: DateRange;

    recordDateRange(filter: DateRange & any): boolean {
        if (filter) {
            const dates = [filter.start_date, filter.end_date];
            if (dates.some(x => !x)) return false;

            if (dates.every(x => x instanceof Date)) {
                this.dateRange = {start_date: dates[0], end_date: dates[1], timezone: filter.timezone || (this.dateRange && this.dateRange.timezone) || null};
                return true;
            }
            this.dateRange = {start_date: new Date(dates[0]), end_date: new Date(dates[1]), timezone: filter.timezone || (this.dateRange && this.dateRange.timezone) || null};
            return true;
        }
        return false;
    }
}
